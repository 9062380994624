<template>
    <div v-loading="isLoading">
        <div class="text-left d-flex align-items-center">
            <div class="pl-2 flex-fill">
                <div>
                    <h4><strong>{{title}}</strong></h4>
                </div>
                
            </div>
            <div class="pr-2">
                <div class="mt-2">
                    <el-input placeholder="Please enter username" 
                              v-model="usernameTarget" 
                              @change="debounceFnc" 
                              style="width: 15rem;"
                              class="mr-1"
                              size="small" />
                    <el-button type="primary" size="small" @click="getData">
                        <i class="el-icon-check"></i> Execute
                    </el-button>
                </div>
            </div>
        </div>
        <highcharts class="mt-4 mr-1" :options="statisticOnSpecialUser.options"></highcharts>
    </div>
</template>

<script>
    import chartDataApi from '@/api/common/chartData';
    var numeral = require('numeral');
    import { debounce } from 'vue-debounce';
    import { Chart } from 'highcharts-vue';
    import {
        areaSplineChart,
        colorSun, colorSat, colorNormal
    } from "@/constant/dashboardChartOptions";
     
    export default {
        components: {
            highcharts: Chart,
        },
        props: {
            title: {
                type: String,
                default: "",
            }
        },
        watch: {
            options: {
                handler: function (newValue) {
                    this.getData();
                },
                deep: true
            }
        },
        data() {
            return {
                debounceFnc: debounce(() => {

                }, 300),
                isLoading: false,
                listData: [],
                usernameTarget: '',
                statisticOnSpecialUser: {
                    options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                        chart: {
                            height: 350,
                            type: 'areaspline'
                        },
                        legend: { enabled: false },
                        tooltip: {
                            shared: true
                        },
                        xAxis: {
                            type: 'datetime',
                        },
                        plotOptions: {
                            areaspline: {
                                dataLabels: {
                                    enabled: true
                                },
                                fillOpacity: 0.5
                            }
                        },
                    }),
                }
            };
        },
        mounted() {
            //this.getData();
            this.debounceFnc = debounce(() => {
                this.getData();
            }, 500);
        },
        methods: {
            getData() {
                this.isLoading = true;
                let requestData = { username: this.usernameTarget };
                chartDataApi.statisticOnSpecialUser(requestData).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.listData = response.data.data;
                        this.statisticOnSpecialUser.options.series = [{
                            name: "Recharged",
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: '$'
                            },
                            data: response.data.data.map(x => {
                                return [new Date(x.DateData).getTime(), x.TotalAmount];
                            })
                        }];
                        this.isLoading = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            this.$message({
                                message: response.data.message || this.$lang.common.error,
                                type: 'warning'
                            });
                        }
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoading = false;
                });
            },
        }
    }
</script>